import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { setupEntryPoint } from "./setupEntryPoint";
import { cartFormSetupEntryPoint } from "./cartFormEntryPoint";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const appEl = document.querySelector("#app");
const cartAppEL = document.querySelectorAll(
  "#lp-form, #confirm-form, #finish-form, #upsell-form, #cv-confirm-form, #cv-upsell-form, #cv-xsell-form",
);

if (appEl) setupEntryPoint().use(pinia).mount("#app");

if (cartAppEL.length)
  cartAppEL.forEach((item) => cartFormSetupEntryPoint().use(pinia).mount(`#${item.id}`));
